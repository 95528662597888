import React from 'react'
import Img from "gatsby-image"
const smokeImg = require('../assets/images/tfog.png')
import styles from './fog.module.scss'

class Fog extends React.Component {
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.back = React.createRef();
    }

    drawFog = () => {
        // fork of
        // http://jsfiddle.net/jonnyc/Ujz4P/5/
        //
        // Create an array to store our particles
        let particles = [];
        // The amount of particles to render
        const particleCount = (this.props.density)? this.props.density : 30;
        // The maximum velocity in each direction
        const maxVelocity = 1;
        // The target frames per second (how often do we want to update / redraw the scene)
        const targetFPS = 33;
        // Set the dimensions of the canvas as variables so they can be used.
        let canvasWidth = window.innerWidth;
        let canvasHeight = window.innerHeight;
        // borders for particles on top and bottom
        const borderTop = 0.01 * canvasHeight;
        const borderBottom = 0.99 * (canvasHeight + 100);
        // Create an image object (only need one instance)
        const imageObj = new Image();
        // Once the image has been downloaded then set the image on all of the particles
        imageObj.onload = function() {
            particles.forEach(function(particle) {
                particle.setImage(imageObj);
            });
        };
        // Once the callback is arranged then set the source of the image
        imageObj.src = (this.props.fog) ? this.props.fog : smokeImg;
        // A function to create a particle object.
        function Particle(drawPad) {
            // Set the initial x and y positions
            this.x = 0;
            this.y = 0;
            // Set the initial velocity
            this.xVelocity = 0;
            this.yVelocity = 0;
            // Set the radius
            this.radius = 5;
            // Store the context which will be used to draw the particle
            this.drawPad = drawPad;
            // The function to draw the particle on the canvas.
            this.draw =  () => {
                // If an image is set draw it
                if (this.image) {
                    this.drawPad.drawImage(this.image, this.x - 128, this.y - 128);
                    // If the image is being rendered do not draw the circle so break out of the draw function
                    return;
                }
                // Draw the circle as before, with the addition of using the position and the radius from this object.
                this.drawPad.beginPath();
                this.drawPad.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
                this.drawPad.fillStyle = "rgba(0, 255, 255, 0.1)";
                this.drawPad.fill();
                this.drawPad.closePath();
            };
            // Update the particle.
            this.update = function () {
                // Update the position of the particle with the addition of the velocity.
                this.x += this.xVelocity;
                this.y += this.yVelocity;
                // Check if has crossed the right edge
                if (this.x >= (canvasWidth + 100)) {
                    this.xVelocity = -this.xVelocity;
                    this.x = canvasWidth;
                }
                // Check if has crossed the left edge
                else if (this.x <= -400) {
                    this.xVelocity = -this.xVelocity;
                    this.x = 0;
                }
                // Check if has crossed the bottom edge
                if (this.y >= borderBottom) {
                    this.yVelocity = -this.yVelocity;
                    this.y = borderBottom;
                }
                // Check if has crossed the top edge
                else if (this.y <= borderTop) {
                    this.yVelocity = -this.yVelocity;
                    this.y = borderTop;
                }
            };
            // A function to set the position of the particle.
            this.setPosition = (x, y) => {
                this.x = x;
                this.y = y;
            };
            // Function to set the velocity.
            this.setVelocity = (x, y) => {
                this.xVelocity = x;
                this.yVelocity = y;
            };
            this.setImage = (image) => {
                this.image = image;
            };
        }
        // A function to generate a random number between 2 values
        let generateRandom = (min, max) => {
            return Math.random() * (max - min) + min;
        }
        // The canvas context if it is defined.
        let drawPad;
        // Initialise the scene and set the context if possible
        const init = () => {
            const canvas = this.canvas.current;
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            if (canvas.getContext) {
                // Set the context variable so it can be re-used
                drawPad = canvas.getContext('2d');
                // Create the particles and set their initial positions and velocities
                for (let i = 0; i < particleCount; ++i) {
                    let particle = new Particle(drawPad);
                    // Set the position to be inside the canvas bounds
                    particle.setPosition(generateRandom(0, canvasWidth), generateRandom(borderTop, borderBottom));
                    // Set the initial velocity to be either random and either negative or positive
                    particle.setVelocity(generateRandom(-maxVelocity, maxVelocity), generateRandom(-maxVelocity, maxVelocity));
                    particles.push(particle);
                }
            } else {
                alert("Please use a modern browser");
            }
        }
        // The function to draw the scene
        const draw = () => {
            particles.forEach((particle) => {
                particle.draw();
            });

        }
        // Update the scene
        const update = () => {
            // drawPad.globalCompositeOperation = "destination-out";
            particles.forEach((particle) => {
                particle.update();
            });
        }
        // Initialize the scene
        init();
        let backImg = this.back.current;
        // If the context is set then we can draw the scene (if not then the browser does not support canvas)
        if (drawPad) {
            window.requestAnimFrame = (function(callback) {
                return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
                    function(callback) {
                        window.setTimeout(callback, 1000 / targetFPS);
                    };
            })();

            function animate() {
                // update
                update();

                // clear
                drawPad.clearRect(0, 0, canvasWidth, canvasHeight);

                // draw stuff
                draw();
                // request new frame
                requestAnimFrame(function() {
                    animate();
                });
            }
            animate();
        }
    }

    componentDidMount(){
        if (this.props.dance) {
            this.drawFog()
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.dance && this.props.dance !== prevProps.dance) {
            this.drawFog()
        }
    }
    render() {
        const { background, dance } = this.props
        return (
            <div className={styles.wrapper}>
                {/*<div className={styles.background} style={{*/}
                    {/*background: 'url(' + background + ') no-repeat center',*/}
                {/*}}></div>*/}
                <Img
                    ref={this.back}
                    fluid={background}
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%"
                    }}
                    alt="Travel Agency in Ranchi"
                />
                {/*<img className={styles.dummy} ref={this.back} src={background} alt=""/>*/}
                {
                    dance ? <canvas className={styles.canvas} ref={this.canvas}></canvas> : null
                }
            </div>
        )
    }
}

export default Fog
