import React from 'react'
import styles from './popular.module.scss'
import {TimelineMax, TweenMax} from "gsap";
import VisibilitySensor from 'react-visibility-sensor'
const mauritius = require('../../assets/images/places/MAURITIUS.jpg')
const plane = require('../../assets/images/plane.png')
const cloud = require('../../assets/images/topCloud.png')

const destinations = [
    {
        name: 'MAURITIUS',
        location: 'PLAISANCE',
        image: require('../../assets/images/places/MAURITIUS.jpg'),
        type: 'International',
        caption: '6 Nights stay with Breakfast & Dinner'
    },
    {
        name: 'Andaman & Nicobar',
        location: 'Havelock Island',
        type: 'Domestic',
        image: require('../../assets/images/places/havelock.jpg'),
        caption: '5 Days with Meal & Travel Assistance'
    },
    {
        name: 'Bali',
        location: 'Uluwatu Temple',
        type: 'International',
        image: require('../../assets/images/places/uluwatu.jpg'),
        caption: '5 Days with Meal & Travel Assistance'
    },
    {
        name: 'Himachal Pradesh ',
        location: 'Kullu, Manali',
        type: 'Domestic',
        image: require('../../assets/images/places/kullu.jpg'),
        caption: '3 Days with Breakfast & Dinner'
    },
    {
        name: 'Singapore',
        location: 'The Merlion',
        type: 'International',
        image: require('../../assets/images/places/merlion.jpg'),
        caption: '5 Days with Meal & Travel Assistance'
    },
    {
        name: 'Bangkok',
        location: 'Wat Phra Keaw',
        type: 'International',
        image: require('../../assets/images/places/watPhraKeaw.jpg'),
        caption: '5 Days with Meal & Travel Assistance'
    }
]

class PopularDestinations extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.plane = React.createRef();
        this.cloud = React.createRef();
        this.sliderTitle = React.createRef();
        this.sliderImage = React.createRef();
        this.sliderCaption = React.createRef();
        this.tween = new TimelineMax();
        // this.slider = new TimelineMax({paused: true});
        // this.rSlider = new TimelineMax({paused: true});
        this.state={
            animated: false,
            activeSlide: 0
        }
    }

    componentDidMount(){
        // for (let i = 1; i <= 3; i++) {
        //     this.slider
        //         .add( TweenMax.to(this.sliderTitle.current.children[i-1], .5, {x: '100%', opacity: 0}) ) //0ms 500ms
        //         .add( TweenMax.fromTo(this.sliderTitle.current.children[i], .5, {x: '-100%'}, {x: '0%', opacity: 1}), '-=0.4')  //100ms 600ms
        //         .add( TweenMax.to(this.sliderImage.current.children[i-1], .5, {x: '100%', opacity: 0}), '-=.3')  //900ms 1400ms
        //         .add( TweenMax.fromTo(this.sliderImage.current.children[i], .5, {x: '-100%'}, {x: '0%', opacity: 1}), '-=.3') //1400ms 1900ms
        //         .add( TweenMax.to(this.sliderCaption.current.children[i-1], .3, {opacity: 0}), '-=0.3')  //300ms 700ms
        //         .add( TweenMax.to(this.sliderCaption.current.children[i], .3, {opacity: 1})) //400ms 900ms
        //         .add( TweenMax.fromTo(this.sliderCaption.current.children[i].children[1], 2, {rotationX:-90, opacity:0},
        //             {rotationX:0, transformOrigin:"50% 0%", opacity: 1, ease:'Elastic.easeOut', immediateRender:true}), '-=0.3') //900ms 1900ms
        //         .addPause();
        // }
    }

    render() {
        const onChange = isVisible => {
            if(this.plane.current){
                console.log('Popular Destination %s', isVisible ? 'visible' : 'hidden');
                if(isVisible && !this.state.animated){
                    console.log('Popular Destination animated');
                    this.tween.fromTo(this.sliderTitle.current.children[0], 0.5, {x:'-100%', opacity: 0}, {x:'0%', opacity: 1})
                        .fromTo(this.sliderCaption.current.children[0], 0.5, {x:'-100%', opacity: 0}, {x:'0%', opacity: 1,zIndex:10, display: 'block'})
                        .fromTo(this.sliderImage.current.children[0], 0.5, {x:'-100%', opacity: 0}, {x:'0%', opacity: 1})
                        .fromTo(this.cloud.current, 0.5, {y:'100%'}, {y:'0%', opacity: 1})
                        .fromTo(this.plane.current, 0.6, {y:'100%', x:'-20%', scale:1}, {y:'0%', x:'0%', opacity: 1, scale:0.8, ease:"easeOut"});
                    this.setState({animated:true})
                }
            }
        }

        const next = () => {
            if (this.state.activeSlide !== destinations.length - 1) {
                TweenMax.killAll();
                this.tween
                    .to(this.sliderTitle.current.children[this.state.activeSlide], .5, {x: '100%', opacity: 0})
                    .fromTo(this.sliderTitle.current.children[this.state.activeSlide + 1], .5, {x: '-100%'}, {x: '0%', opacity: 1}, '-=0.4')
                    .to(this.sliderImage.current.children[this.state.activeSlide], .5, {x: '100%', opacity: 0}, '-=.3')
                    .fromTo(this.sliderImage.current.children[this.state.activeSlide + 1], .5, {x: '-100%'}, {x: '0%', opacity: 1}, '-=.3')
                    .to(this.sliderCaption.current.children[this.state.activeSlide], .3, {opacity: 0, zIndex:0, display: 'none'}, '-=0.3')
                    .to(this.sliderCaption.current.children[this.state.activeSlide + 1], .3, {opacity: 1, zIndex:10, display:'block'})
                    .fromTo(this.sliderCaption.current.children[this.state.activeSlide + 1].children[1], 2, {rotationX:-90, opacity:0},
                        {rotationX:0, transformOrigin:"50% 0%", opacity: 1, ease:'Elastic.easeOut', immediateRender:true}, '-=0.3')
                // this.slider.timeScale( 1 )
                // this.slider.play();
                this.setState((prevState) => {
                    return {activeSlide: prevState.activeSlide + 1}
                })
            }

        }

        const prev = () => {
            if (this.state.activeSlide !== 0) {
                TweenMax.killAll();
                this.tween
                    .to(this.sliderTitle.current.children[this.state.activeSlide], .5, {x: '-100%', opacity: 0} )
                    .to(this.sliderImage.current.children[this.state.activeSlide], .5, {x: '-100%', opacity: 0}, '-=.5')
                    .to(this.sliderCaption.current.children[this.state.activeSlide], .3, {opacity: 0, zIndex:0, display: 'none'}, '-=0.2')
                    .fromTo(this.sliderTitle.current.children[this.state.activeSlide - 1], .5, {x: '100%'}, {x: '0%', opacity: 1}, '-=0.1')
                    .fromTo(this.sliderImage.current.children[this.state.activeSlide - 1], .5, {x: '100%'}, {x: '0%', opacity: 1}, '-=.1')
                    .to(this.sliderCaption.current.children[this.state.activeSlide - 1], .3, {opacity: 1,zIndex:10, display: 'block'})
                    .fromTo(this.sliderCaption.current.children[this.state.activeSlide - 1].children[1], 2, {rotationX:-90, opacity:0},
                        {rotationX:0, transformOrigin:"50% 0%", opacity: 1, ease:'Elastic.easeOut', immediateRender:true}, '-=0.3')

                this.setState((prevState) => {
                    return {activeSlide: prevState.activeSlide - 1}
                })
                // this.slider.timeScale( 3 )
                // this.slider.reverse();
            }
        }

        return (
            <div className={ styles.popularDestinations }>
                <VisibilitySensor onChange={onChange}>
                    <span className="trigger"> &nbsp;</span>
                </VisibilitySensor>
                <section className="hero is-fullheight has-padding-top-md is-clipped">
                    <div className="hero-head" style={{position: 'relative'}}>
                        <div className="columns">
                            <div className="column is-three-fifths is-offset-2 section-head">
                                <div className="columns has-text-centered">
                                    <div className={styles.popularTitle + " column is-4 is-3-fullhd has-padding-lg"} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <h2 className="is-size-4 is-uppercase has-text-left section-title has-text-weight-bold">
                                            Popular<br/><small className="has-padding-left-md">Destinations</small>
                                        </h2>
                                    </div>
                                    <p className="column is-8 is-size-5 is-capitalized has-color-accent has-text-centered text-montserrat">Best Travel Destinations Domestic & International for Couple, Family and Groups Customised as per needs from the Agency.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="hero-body has-padding-left-none has-padding-right-none has-padding-bottom-none">
                            <div className="columns is-clipped is-multiline" style={{flex:1}}>
                                <div className={styles.destination + " column is-7 is-offset-2 is-paddingless"}>
                                    <div className="columns is-marginless">
                                        <div className="column is-10 is-offset-2 has-padding-md has-background-accent">
                                            <div className={styles.titleHolder} ref={this.sliderTitle}>
                                                {
                                                    destinations.map((destination, index) => <h2 key={index} className="has-padding-left-lg">{destination.name} <small>( {destination.location} )</small></h2>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.imageHolder} ref={this.sliderImage}>
                                        {
                                            destinations.map((destination, index) =>
                                                <div className="content-image" key={index} style={{
                                                    background: 'url('+destination.image+') center no-repeat',
                                                    backgroundSize: 'cover'
                                                }}>
                                                </div>
                                            )
                                        }
                                        <img className="is-invisible" src={mauritius} alt="Travel Agency in Ranchi"/>
                                    </div>
                                </div>
                                <div className="column is-2 has-text-centered">
                                    <ul className="is-inline-flex">
                                        <li>
                                            <a className={((this.state.activeSlide === 0) ? "is-disabled" : "") + " icon make-it-circle icon-navigation"} onClick={prev}>
                                                <i className="fas fa-angle-left is-size-3"></i>
                                            </a>
                                        </li>
                                        <li className="has-margin-left-md">
                                            <a className={((this.state.activeSlide === destinations.length - 1) ? "is-disabled" : "") + " icon make-it-circle icon-navigation"} onClick={next}>
                                                <i className="fas fa-angle-right is-size-3"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.destinationFooter + " column is-9 "} ref={this.sliderCaption}>
                                    {
                                        destinations.map((destination, index) => <div key={index}><p className="text-montserrat is-centered">{destination.caption}</p><span data-package={destination.name} onClick={(e) => this.props.modal(e)} className="text-montserrat">BOOK TODAY</span></div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="hero-foot" style={{position:'relative'}}>
                            <figure ref={this.plane} className={styles.plane+ " image"} >
                                <img src={plane} alt="Travel Agency in Ranchi"/>
                            </figure>
                            <figure ref={this.cloud} className={styles.cloud+ " image"}>
                                <img src={cloud} alt="Travel Agency in Ranchi"/>
                            </figure>
                        </div>
                </section>
            </div>
        )
    }
}

export default PopularDestinations
