import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import {TimelineMax} from 'gsap'
import styles from './about.module.scss'
const headerBg = require('../../assets/images/topBg.png');
const aboutBg = require('../../assets/images/aboutBg.jpg');
const plane = require('../../assets/images/plane.png');
const cloud = require('../../assets/images/topCloud.png');



class About extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.plane = React.createRef();
        this.cloud = React.createRef();
        this.tween = new TimelineMax();
        this.state = {
            animated: false
        }
    }

    render() {
        // const {classes} = this.props;
        const onChange = isVisible => {
            console.log('About is %s', isVisible ? 'visible' : 'hidden');
            if (this.plane.current) {
                if (isVisible && !this.state.animated) {
                    console.log('About animated');
                    // if(isVisible){
                    this.tween.fromTo(this.cloud.current, 0.5, {y: '-100%'}, {y: '0%', opacity: 1})
                        .fromTo(this.plane.current, 0.6, {y: '20%', x: '150%'}, {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            scale:0.8,
                            ease: "easeOut"
                        });
                    this.setState({animated: true})
                }
            }
        };
        return (
            <div className={styles.about} style={{background: 'url(' + aboutBg + ') no-repeat center'}} id="about">
                <VisibilitySensor onChange={onChange}>
                    <span className="trigger"> &nbsp;</span>
                </VisibilitySensor>
                <section className="hero is-fullheight has-padding-md is-clipped">
                    <div className="hero-head" style={{position: 'relative'}}>
                        <div className="columns">
                            <div className="column is-11 is-offset-1 section-head">
                                <div className="columns has-text-centered">
                                    <div className={styles.aboutTitle + " column is-2"}>
                                        <h2 className="is-size-4 is-uppercase has-text-white section-title has-text-weight-bold">
                                            About <br/>Us
                                        </h2>
                                    </div>
                                    <div
                                        className={styles.aboutBody + " column is-10 has-padding-lg has-padding-left-xl has-padding-right-xl has-text-left"} style={{background: 'url(' + headerBg + ') no-repeat', backgroundSize:'cover'}}>
                                        <p className="text-montserrat has-color-accent" >
                                            MTT specializes in organizing some of the best tours worldwide specially curated for your needs,
                                            in over 150 countries across the globe. If you’re looking for a unique travel experience,
                                            whether it be for a weekend or for months we will provide you the best travel experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <figure ref={this.cloud} className={styles.cloud + " image"} style={{opacity: 0}}>
                            <img src={cloud} alt="Travel Agency in Ranchi"/>
                        </figure>
                    </div>
                    <div className={styles.heroBody + " hero-body"} >
                        <div className="columns is-centered is-multiline">
                            <div className="column is-full">
                                <h1 className="has-text-centered title is-size-2 text-montserrat is-uppercase has-color-accent">Minj Tour & Travels</h1>
                                <h2 className="has-text-centered subtitle is-size-5-fullhd has-text-weight-bold is-italic is-uppercase has-color-secondary is-6"
                                    style={{letterSpacing: '2px'}}>Best Travel Packages Domestic as well as International.</h2>
                            </div>
                            <div className="column is-three-quarters has-text-centered text-courgette"
                                 style={{
                                     lineHeight: 1.8
                                 }}>
                                <p className="is-size-6 is-size-5-fullhd has-text-weight-semibold has-text-grey-dark">
                                    <strong>Minj tour and travels</strong> established in 2014 with the idea to provide domestic and International tours at cheaper costs. We Established the <strong>Travel Agency </strong>in <strong>Ranchi</strong> the capital of Jharkhand, We believe every person should have the privilege to travel around the world in their lifetime.
                                    <strong>Travel</strong> is an ideal activity to test your limits. It pushes you to limit, you discover how resourceful you are when you are exposed to new places, people and experiences.
                                    It helps open your mind you realise there is no one way to live life.
                                </p>
                                <p className="is-size-6 is-size-5-fullhd has-text-weight-semibold has-text-grey-dark">
                                    Whether you want to travel alone, with your significant other, with family we help you plan, streamline all the boring aspects of travel so you just focus on achieving, discovering and strengthening yourself.
                                    We provide guided tours worldwide as well as prepare Best personal travel packages.
                                </p>
                                <div className="columns">
                                    <div className="column is-half">
                                        <ul className={styles.list}>
                                            <li>Passport Assistance.</li>
                                            <li>Visa Assistance.</li>
                                            <li>Currency Exchange.</li>
                                        </ul>
                                    </div>
                                    <div className="column is-half">
                                        <ul className={styles.list}>
                                            <li>Accommodations.</li>
                                            <li>Pickup & Drop.</li>
                                            <li>Air and Train Ticketing.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-foot container" style={{position: 'relative'}}>
                        <div className="level">
                            <div className="level-left" style={{position: 'relative', zIndex: '4', maxWidth: '100%'}}>
                                <p className="text-montserrat has-text-justified has-color-accent is-size-5-fullhd">
                                    Start the Adventure here by searching for your ideal trip destination,
                                    or get in touch so we can plan to provide you the best travel experience.
                                </p>
                            </div>
                            <div className="level-right">

                            </div>
                        </div>
                        <figure ref={this.plane} className={styles.plane + " image"} style={{opacity: 0}}>
                            <img src={plane} alt="Travel Agency in Ranchi"/>
                        </figure>
                    </div>
                </section>
            </div>
        )
    }
}

export default About
