import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Cover from '../components/Cover'
import Layout from '../components/Layout'
import About from '../components/About'
import PopularDestinations from '../components/PopularDestinations'
import Tours from '../components/Tours'
import Upcoming from '../components/UpcomingTours'
import Travel from '../components/Travel'
import Contact from '../components/Contact'
import Modal from "../components/Modal";
import {TimelineMax} from "gsap";
// import { rhythm } from '../utils/typography'


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    modalToggle = (e) => {
        console.log(e.target.dataset.package)
        this.modal.current.modalToggle(e.target.dataset.package);
    };

    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const siteDescription = get(
            this,
            'props.data.site.siteMetadata.description'
        )
        return (
            <Layout location={this.props.location}>
                <Helmet
                    htmlAttributes={{ lang: 'en' }}
                    meta={[{ name: 'description', content: siteDescription },{httpEquiv:'Content-type', content:'text/html;charset=UTF-8'}]}
                    title={siteTitle}
                />
                <Cover/>
                <About/>
                <PopularDestinations modal={this.modalToggle}/>
                <Tours modal={this.modalToggle}/>
                <Upcoming modal={this.modalToggle}/>
                <Travel/>
                <Contact/>
                <Modal ref={this.modal}/>
            </Layout>
        )
    }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
