import React from 'react'
import {Link} from 'gatsby'
import styles from './contact.module.scss'
const map = require('../../assets/images/minjMap.png');
const themaps = require('../../assets/images/mapsLogo.png');


class Contact extends React.Component {
    render() {

        return (
            <div className={styles.contact} id="contact">
                <section className="hero is-fullheight is-clipped">
                    <div className={styles.head + " hero-head"}>
                        <div className="container">
                            <div className="level">
                                <div className="level-item">
                                    <h5 className="is-size-6">
                                    <span className="icon make-it-circle">
                                      <i className="fas fa-envelope"/>
                                    </span> &nbsp;
                                        bimal@minjtourntravels.com
                                    </h5>
                                </div>
                                <div className="level-item">
                                    <h5 className="is-size-6">
                                    <span className="icon make-it-circle">
                                      <i className="fas fa-phone"/>
                                    </span> &nbsp;
                                        +91-8709285656 , +919955120517
                                    </h5>
                                </div>
                                <div className="level-item">
                                    <h5 className="is-size-6">
                                    <span className="icon make-it-circle">
                                      <i className="fas fa-map-marked-alt"/>
                                    </span> &nbsp;
                                        Peace Road, Bye Lane, Lalpur, Ranchi, Jharkhand
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-body has-padding-left-none has-padding-right-none has-padding-bottom-none">

                    </div>
                    <div className={styles.foot + " hero-foot has-text-centered"}>
                        <div className="container">
                            <div className="level">
                                <div className="level-item box" style={{maxWidth: '520px', backgroundColor: '#1889f1', color: '#fff'}}>
                                    <article className="media" style={{alignItems: 'center'}}>
                                        <div className="media-left" style={{width: '80px'}}>
                                            <figure className="image">
                                                <img src={themaps} alt="Image"/>
                                            </figure>
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <p className="text-montserrat">
                                                    For Booking of photo & videos for Lota-Paani(Engagement) / Bara Mehemani / Marriage / Picnic Programmes. <br/> Contact : <em>+91-7004791076/ +91-9570006882</em>
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="level-item box" style={{maxWidth: '520px', backgroundColor: '#1889f1', color: '#fff'}}>
                                    <article className="media" style={{alignItems: 'center'}}>
                                        <div className="media-left" style={{width: '80px'}}>
                                            <p className="has-text-centered title is-size-6 text-montserrat is-uppercase has-color-accent">Easter Special</p>
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <p className="text-montserrat">
                                                    7” Candle Better quality than which we see in market .Pack of 12 pieces of candle sticks at &#8377; 45.<br/> May you feel the bright, joyful blessings God has to offer you during this Easter holiday. <br/>Contact : <em>+91-8709285656/ +91-9955120517</em>
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="columns is-vcentered">
                                <div className="column is-4">
                                    <figure className="image">
                                        <img src={map} alt="Travel Agency in Ranchi"/>
                                    </figure>
                                </div>
                                <div className="column is-8">
                                    {/*<h4 className="title is-5 has-text-left">Important Links</h4>*/}
                                    <div className="columns">
                                        <div className="column">
                                            <div className="menu has-text-left">
                                                <p className="menu-label has-text-white">
                                                    Pilgrimage Tours
                                                </p>
                                                <ul className="menu-list">
                                                    <li><Link to="/itineraries/international/holyland/">Holy Land
                                                        Tour</Link></li>
                                                    <li><Link to="/itineraries/international/europe/">Europe Tour</Link>
                                                    </li>
                                                    <li><Link to="/itineraries/domestic/velankanni/">Velankanni
                                                        Tour</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="menu has-text-left">
                                                <p className="menu-label has-text-white">
                                                    Honeymoon Packages
                                                </p>
                                                <ul className="menu-list">
                                                    <li><Link to="/itineraries/international/mauritius/">Mauritius
                                                        Package</Link></li>
                                                    <li><Link to="/itineraries/international/phuket&karabi">Phuket &
                                                        Karabi Package</Link></li>
                                                    <li><Link to="/itineraries/domestic/andaman">Andaman Islands</Link>
                                                    </li>
                                                    <li><Link to="/itineraries/domestic/ooty&kodaikanal">Ooty &
                                                        Kodaikanal</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="menu has-text-left">
                                                <p className="menu-label has-text-white">
                                                    Connect with us
                                                </p>
                                                <ul className="menu-list">
                                                    <li className="has-text-left">
                                                        <a href="https://www.facebook.com/Minj-Tours-Travels-110047763204485/"
                                                           target="_blank" rel="noopener noreferrer">
                                                            <span className="icon">
                                                              <i className="fab fa-facebook"/>
                                                            </span>Facebook
                                                        </a>
                                                    </li>
                                                    <li className="has-text-left">
                                                        <a href="https://www.instagram.com/minjtourntravels/"
                                                           target="_blank" rel="noopener noreferrer">
                                                            <span className="icon">
                                                              <i className="fab fa-instagram"/>
                                                            </span>Instagram
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-12">
                                        <p className="has-text-right">
                                            <span className="icon">
                                              <i className="far fa-copyright"/>
                                            </span> 2019 Minj Tour & Travels | Developed at&nbsp;
                                            <a href="http://theroguepixel.com" className="has-text-white trp"
                                               target="_blank" rel="noopener noreferrer">
                                                the, rogue Pix
                                                <small>x</small>
                                                el
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
    )
    }
    }

    export default Contact
