import React from "react"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

export default props => (
    <StaticQuery
        query={graphql`
          query nameLogoQuery{
              logo: file(relativePath: {eq: "images/Mtt.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                }
              }
          }
        `}
        render={data => <Img {...props} fluid={data.logo.childImageSharp.fluid} alt="Logo | Travel Agency in Ranchi" />}
    />
)
