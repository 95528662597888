import React from 'react'
import styles from './upcoming.module.scss'
import {TimelineMax, TweenMax} from "gsap";
import VisibilitySensor from 'react-visibility-sensor'
import {StaticQuery, graphql, Link} from "gatsby";

const plane = require('../../assets/images/plane.png')
const cloud = require('../../assets/images/topCloud.png')

class PopularDestinations extends React.Component {
    constructor(props) {
        super(props);
        this.plane = React.createRef();
        this.cloud = React.createRef();
        this.tween = new TimelineMax();
        this.state = {
            animated: false,
            activeSlide: 0
        }
    }

    componentDidMount() {
    }

    render() {
        const onChange = isVisible => {
            console.log('Tour is %s', isVisible ? 'visible' : 'hidden');
            if (this.plane.current) {
                if (isVisible && !this.state.animated) {
                    // if(isVisible){
                    console.log('Tour animated');
                    this.tween.fromTo(this.cloud.current, 0.5, {x: '50%'}, {x: '0%', opacity: 1})
                        .fromTo(this.plane.current, 0.6, {x: '150%', y: '-30%', scale: 1}, {
                            x: '0%',
                            y: '0%',
                            opacity: 1,
                            scale: 0.8,
                            ease: "easeOut"
                        });
                    this.setState({animated: true})
                }
            }
        }

        const bookingOpen = (title, link) =>  <div>
            <p className="subtitle has-text-success is-marginless has-text-centered">Bookings open</p>
            <div className="level">
                <div className={styles.book + " level-left"}>
                    <a data-package={title} onClick={(e) => this.props.modal(e)}>Book Now</a>
                </div>
                <div className={styles.book + " level-right"}>
                    <Link to={link}>
                        View Itinerary
                    </Link>
                </div>
            </div>

        </div>
        const bookingClosed = link => <div className="has-text-centered">
            <p className="subtitle has-text-warning is-marginless has-padding-bottom-xs">Bookings Closed</p>
            <div className={styles.book}>
                <Link to={link}>
                    View Itinerary
                </Link>
            </div>
        </div>

        return (
            <div className={styles.upcomingTours}>
                <VisibilitySensor onChange={onChange}>
                    <span className="trigger"> &nbsp;</span>
                </VisibilitySensor>
                <section className="hero is-fullheight has-padding-top-md is-clipped">
                    <div className="hero-head" style={{position: 'relative'}}>
                        <figure ref={this.plane} className={styles.plane + " image"}>
                            <img src={plane} alt="Travel Agency in Ranchi"/>
                        </figure>
                        <figure ref={this.cloud} className={styles.cloud + " image"}>
                            <img src={cloud} alt="Travel Agency in Ranchi"/>
                        </figure>
                        <div className="columns is-centered">
                            <div className="column is-3 is-vertical-center">
                                <div className="columns is-mobile" style={{
                                    position: 'relative',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    zIndex: 1
                                }}>
                                    <div className={styles.left + " column"}>
                                        <h2 className="is-size-3 is-uppercase has-text-centered section-title has-text-weight-bold is-inline-block has-text-white">
                                            UP
                                            <br/>
                                            <hr className="is-marginless"/>
                                            <small className="is-size-5 has-text-weight-normal">COMING</small>
                                        </h2>
                                    </div>
                                    <div className={styles.right + " column is-vertical-center"}>
                                        <h2 className="is-size-3 is-uppercase has-text-centered section-title has-text-weight-light is-inline-block has-text-white">TOURS</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="is-size-5 is-capitalized has-color-accent has-text-centered text-montserrat"
                           style={{
                               position: 'relative',
                               zIndex: 1
                           }}>Best Group Tour Packages with travel Enthusiasts and a <strong className="has-color-accent">Tour Guide</strong> from Agency.</p>
                    </div>
                    <div className="hero-body">

                        <StaticQuery
                            query={graphql`
                                      query {
                                          allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 1000, filter: {fileAbsolutePath: {regex: "/(/group-tours)/.*.md$/"}}) {
                                            edges {
                                              node {
                                                fields {
                                                  slug
                                                }
                                                frontmatter {
                                                  departure {
                                                    date
                                                    month
                                                  }
                                                  title
                                                  name
                                                  duration
                                                  cost
                                                  type
                                                  last
                                                  highlights{
                                                    name
                                                    faIcon
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                    `}
                            render={data => (
                                <div className={styles.tourContainer + " columns is-multiline is-centered"}>
                                    {
                                        data.allMarkdownRemark.edges.map((tour, index) =>
                                            <div className={styles.cardContent + " column is-3-desktop is-4-tablet"} key={index}>
                                                    <div className="date is-clearfix">
                                                        <span className="has-text-weight-bold is-size-1 is-pulled-left">
                                                            {tour.node.frontmatter.departure.date}
                                                        </span>
                                                        <span className="is-pulled-left is-size-4 has-padding-left-md">
                                                            {tour.node.frontmatter.departure.month} <br/>
                                                            <span className=" is-size-6">{tour.node.frontmatter.duration}</span>
                                                        </span>
                                                    </div>
                                                    <p className="info has-text-black-bis title is-size-4 has-padding-top-md is-marginless">
                                                        {tour.node.frontmatter.name}
                                                    </p>
                                                    <p className="subtitle is-marginless is-size-4 has-text-weight-bold has-padding-top-md">
                                                        &#8377; {tour.node.frontmatter.cost}
                                                    </p>
                                                    <div className={styles.tourHighlights}>
                                                        {
                                                            tour.node.frontmatter.highlights.map((highlight, index) =>
                                                                <span key={index}>
                                                                    <abbr title={highlight.name}><i className={highlight.faIcon}/></abbr>
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                    <hr/>
                                                    {
                                                        new Date() > new Date(tour.node.frontmatter.last) ? bookingClosed(tour.node.fields.slug) : bookingOpen(tour.node.frontmatter.title, tour.node.fields.slug)
                                                    }
                                            </div>
                                        )
                                    }
                                </div>
                            )}
                        />

                    </div>
                    <div className="hero-foot" style={{position:'relative'}}>

                    </div>
                </section>
            </div>
        )
    }
}

export default PopularDestinations
