import React from 'react'
import Img from "gatsby-image"
import {StaticQuery, graphql} from 'gatsby'
import styles from './tours.module.scss'
import VisibilitySensor from 'react-visibility-sensor'
import {TimelineMax} from "gsap";
import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';

const plane = require('../../assets/images/plane.png')
const cloud = require('../../assets/images/topCloud.png')


class Tours extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.plane = React.createRef();
        this.cloud = React.createRef();
        this.tween = new TimelineMax();
        this.state = {
            animated: false,
            tours: []
        }
    }

    tourTabs = data => {
        return data.map((tour, index) => ({
            title: tour.node.frontmatter.title,
            getContent: () => {
                return <div
                    className="columns is-multiline has-padding-left-xl has-padding-right-xl has-padding-bottom-xl has-text-centered" style={{position:'relative'}}>
                    <div className={styles.tourDate}>
                        <h2>{tour.node.frontmatter.tour}</h2>
                        <h3>2019</h3>
                    </div>

                    <div className={styles.tourLink}>
                        <a data-package={tour.node.frontmatter.title} onClick={(e) => this.props.modal(e)}>Book Now</a>
                    </div>
                    {
                        tour.node.frontmatter.featured.map((image, index) => {
                            return <div className="column is-4" key={index}>
                                <Img className="is-marginless" fluid={image.image.childImageSharp.fluid}
                                     title={image.name} alt="Travel Agency in Ranchi"/>
                                <h3 className="is-size-6 has-text-centered has-text-weight-bold is-uppercase">{image.name}</h3>
                            </div>
                        })
                    }
                    <div className={styles.tourOffer}>
                        <p>{tour.node.frontmatter.offer} <br/> <a href={tour.node.fields.slug}>Know More</a></p>
                    </div>
                </div>
            },
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
        }))
    }

    render() {
        const onChange = isVisible => {
            console.log('Packages is %s', isVisible ? 'visible' : 'hidden');
            if (this.plane.current) {
                if (isVisible && !this.state.animated) {
                    // if(isVisible){
                    console.log('Packages animated');
                    this.tween.fromTo(this.cloud.current, 0.5, {x: '-80%'}, {x: '0%', opacity: 1})
                        .fromTo(this.plane.current, 0.6, {x: '-150%', y: '10%', scale: 1}, {
                            x: '0%',
                            y: '0%',
                            opacity: 1,
                            scale: 0.8,
                            ease: "easeOut"
                        });
                    this.setState({animated: true})
                }
            }
        }

        return (
            <div className={styles.tours}>
                <VisibilitySensor onChange={onChange}>
                    <span className="trigger"> &nbsp;</span>
                </VisibilitySensor>
                <section className="hero is-fullheight has-padding-top-md is-clipped">
                    <div className="hero-head" style={{position: 'relative'}}>
                        <figure ref={this.plane} className={styles.plane + " image"}>
                            <img src={plane} alt="Travel Agency in Ranchi"/>
                        </figure>
                        <figure ref={this.cloud} className={styles.cloud + " image"}>
                            <img src={cloud} alt="Travel Agency in Ranchi"/>
                        </figure>
                        <div className="columns">
                            <div className="column is-three-fifths is-offset-2 section-head">
                                <div className="columns has-text-centered is-centered">
                                    <div className={styles.popularTitle + " column is-4 is-3-fullhd has-padding-lg"} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <h2 className="is-size-4 is-uppercase has-text-left section-title has-text-weight-bold has-text-grey-dark">
                                            Pilgrimage<br/><small className="">Tours</small>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="is-size-5 is-capitalized has-color-accent has-text-centered text-montserrat" style={{position: 'relative',zIndex:1}}>Best Travel Packages around the World with a Certified <strong className="has-color-accent">Tour Guide</strong> from Agency.</p>
                    </div>
                    <div className="hero-body has-padding-left-none has-padding-right-none has-padding-bottom-none">
                        <div className={styles.content + " container is-centered"}>
                            <span className="is-invisible" style={{position: 'absolute'}}>Tours</span>
                            <StaticQuery
                                query={graphql`
                                      query {
                                          allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 3, filter:  { frontmatter:  { type: { eq:"pilgrimage"}}}) {
                                            edges {
                                              node {
                                                fields {
                                                  slug
                                                }
                                                frontmatter {
                                                  title
                                                  offer
                                                  tour
                                                  featured {
                                                    name
                                                    image {
                                                      childImageSharp {
                                                        fluid(maxWidth: 630) {
                                                          ...GatsbyImageSharpFluid
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                    `}
                                render={data => (
                                    <Tabs items={this.tourTabs(data.allMarkdownRemark.edges)}/>
                                )}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Tours
