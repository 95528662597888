import React from 'react'
import Logo from '../nameLogo'
// Import typefaces
import VisibilitySensor from 'react-visibility-sensor'
import styles from './cover.module.scss'
import Fog from '../Fog'
import {StaticQuery, graphql} from 'gatsby'

class Cover extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            visible: false,
            nightMode: false
        }
    }

    componentDidMount(){
        const time = new Date().getHours();
        this.setState({
            nightMode: (time < 4 || time > 19)
        })
    }
    render() {
        const onChange = isVisible => {
            console.log('Cover Fog animation %s', isVisible ? 'running' : 'stopped');
            (isVisible) ? this.setState({
                    visible:true
                }) :
                this.setState({
                    visible:false
                })

        }
        const handleCheckClick = () => {
            this.setState(prevState => ({
                nightMode: !prevState.nightMode
            }));
        }
        return (
            <div className={styles.wrap}>
                <VisibilitySensor onChange={onChange} offset={{top:-400}}>
                    <span className="trigger"> &nbsp;</span>
                </VisibilitySensor>
                <div className="content">
                    {
                        (this.state.nightMode) ?
                            <video autoPlay loop>
                                <source src="/milkyWay.webm" type="video/webm"/>
                                <source src="/milkyWay.mp4" type="video/mp4"/>
                            </video> :
                            <StaticQuery
                                query={graphql`
                                  query BackgroundQuery{
                                      background: file(relativePath: {eq: "images/shipBanner.jpg"}) {
                                        childImageSharp {
                                            fluid(maxWidth: 1920) {
                                              ...GatsbyImageSharpFluid
                                            }
                                        }
                                      }
                                  }
                                `}
                                render={data => (
                                    <Fog background={data.background.childImageSharp.fluid} dance={this.state.visible} />
                                )}
                            />
                    }
                    <section className="hero is-fullheight">
                        <div className="hero-head">
                            <div className="container has-text-right" style={{
                                position: 'relative',
                                top: '110px'
                            }}>
                            </div>
                        </div>
                        <div className="hero-body is-paddingless">
                            <div className="container has-text-centered" style={{
                                background: 'rgba(255, 255, 255, '+(this.state.nightMode ? '0.09': '0.3')+')',
                                padding: '4rem 0',
                                maxWidth: '100%',
                                color: '#113e67'
                            }}>
                                <figure className="image brand">
                                    <Logo/>
                                </figure>
                                <h2 className="subtitle is-size-3 text-bradely"
                                    style={{
                                        color: '#ff0000',
                                        fontWeight: '700'
                                    }}>
                                    Touching Hearts in each Touch
                                </h2>
                                <p className="text-montserrat"> Contact: <a className="has-text-white" href="tel:+918709285656">+91-(870)92-85656 ,</a>
                                    <a className="has-text-white" href="tel:+919955120517">+91-(995)51-20517</a>
                                </p>
                            </div>
                        </div>
                        <div className="hero-foot">
                            <div className={styles.switchWrapper}>
                                <input id="switchCheckbox" type="checkbox" checked={this.state.nightMode} onChange={handleCheckClick}/>
                                <label htmlFor="switchCheckbox" className="switch"></label>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default Cover
