import React from 'react'
import { Link } from 'gatsby'

// Import typefaces
import 'typeface-montserrat'
import styles from './travel.module.scss'
import {StaticQuery, graphql} from 'gatsby'
import {TimelineMax} from "gsap";
import Earth from '-!svg-react-loader!../../assets/svgs/earth.svg';
import VisibilitySensor from 'react-visibility-sensor'
import Fog from '../Fog'
const Smoke = require('../../assets/images/fog-particle.png');
import Logo from '../nameLogo'


class Travel extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.overlay = React.createRef();
        this.tween = new TimelineMax({repeat:-1, paused: true});
        this.state={
            visible: false,
            active: false
        }
    }
    componentDidMount(){

        this.tween.to(this.overlay.current, 60, {backgroundPosition:"-2280px 0px", ease:"Linear.easeNone"});
    }
    render() {

        const onChange = isVisible => {
            console.log('Travel Fog animation %s', isVisible ? 'running' : 'stopped');
            if (isVisible) {
                this.setState({
                    visible: true
                });
                this.tween.play();

            } else {
                this.setState({
                    visible: false
                });
                this.tween.pause();
            }
        };

        return (
            <div className={ styles.tours }>
                <div className={styles.cover}>
                    <section className="hero is-fullheight is-clipped">
                        <StaticQuery
                            query={graphql`
                          query {
                              background: file(relativePath: {eq: "images/travelBgFog.jpg"}) {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                      ...GatsbyImageSharpFluid
                                    }
                                }
                              }
                          }
                        `}
                            render={data => (
                                <Fog background={data.background.childImageSharp.fluid}
                                     density={20}
                                     fog={Smoke}
                                     dance={this.state.visible}
                                />
                            )}
                        />
                        <div className={styles.head+ " hero-head"} ref={this.overlay}>
                            <div className="overlay has-text-centered is-vertical-center">
                                <h2 className="is-size-2 has-text-centered text-montserrat">Travel Packages around the World <br/><Link to="/packages" className="button">Browse Now</Link></h2>
                            </div>
                        </div>
                        <div className="hero-body has-padding-left-none has-padding-right-none has-padding-bottom-none">

                        </div>
                        <VisibilitySensor onChange={onChange} offset={{top:-300, bottom:-500}}>
                            <div className={styles.foot + " hero-foot has-text-centered"}>
                                {/*<img src={earth} alt="" />*/}
                                <Earth/>
                                {/*<Earth id="dummyEarth" className={styles.dummyEarth}/>*/}
                                <Logo/>
                                <h1 className="is-size-3 has-text-white text-montserrat">Tours | Travel | Adventure</h1>
                            </div>
                        </VisibilitySensor>
                    </section>
                </div>
            </div>
        )
    }
}

export default Travel
